
/**
 * @roxi/routify 2.18.2
 * File generated Fri Jul 23 2021 10:22:15 GMT+0000 (Coordinated Universal Time)
 */

export const __version = "2.18.2"
export const __timestamp = "2021-07-23T10:22:15.080Z"

//buildRoutes
import { buildClientTree } from "@roxi/routify/runtime/buildRoutes"

//imports


//options
export const options = {}

//tree
export const _tree = {
  "root": true,
  "children": [
    {
      "isIndex": true,
      "isPage": true,
      "path": "/index",
      "id": "_index",
      "component": () => import('../src/pages/index.svelte').then(m => m.default)
    }
  ],
  "path": "/"
}


export const {tree, routes} = buildClientTree(_tree)

